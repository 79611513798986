(function () {
  'use strict';

  angular
    .module('neo.home.championships.championship.draw')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.championships.championship.draw', {
        url: '/draw',
        templateUrl: 'home/championships/championship/draw/draw.tpl.html',
        controller: 'DrawCtrl',
        controllerAs: 'vm',
        resolve: {
          teams: assignedTeams,
          matchDaysDates: assignedDates
        }
      });

    function assignedTeams(AssignTeams, $stateParams) {
      return AssignTeams.query({championshipId: $stateParams.championshipId}).$promise;
    }

    function assignedDates(PhaseMatchDates, championship) {
      return PhaseMatchDates.query({phaseId: championship.phaseId}).$promise;
    }
  }
}());
